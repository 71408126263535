export default defineNuxtRouteMiddleware(async (to) => {
  const submissionId = to.params?.submissionId;
  const teamId = to.params?.teamId;
  const team = await useQuizTeam().byId(teamId as string);

  if (!teamId || !team || team.finished_at) {
    return submissionId
      ? navigateTo({ name: 'quiz.teams', params: { submissionId } })
      : navigateTo({ name: 'quiz.select' });
  }
});
